import React, {useRef, useState} from 'react';
import {
  CircularProgress, makeStyles,
} from "@material-ui/core"
import Fab from "@material-ui/core/Fab"
import Colors from "../constants/Colors"
import MyLocationIcon from "@material-ui/icons/MyLocation"
import clsx from "clsx"

const useStyles = makeStyles(theme => ({
  iconButton: {
    maxWidth: "44px",
    maxHeight: "44px",
    backgroundColor: Colors.white,
    boxShadow: "0 2px 2px 0 rgba(47, 66, 89, 0.1), 0 2px 1px -2px rgba(47, 66, 89, 0.15), 0 1px 3px 0 rgba(47, 66, 89, 0.1)",
    '&:hover': {
      boxShadow: "0 7px 14px -6px rgba(47, 66, 89, 0.30), 0 2px 11px 0px rgba(0, 0, 0, 0.12), 0 4px 5px -2px rgba(47, 66, 89, 0.2)",
      backgroundColor: Colors.white,
    }
  },
  innerIcon: {
    color: Colors.azure,
  }
}));

export default ({ onStateSelected, onGetCurrentPosition, classes, label }) => {
  classes = Object.assign({}, classes, useStyles());
  const geolocationWatchID = useRef(null);
  const [isRetrievingPosition, setIsRetrievingPosition] = useState(false);

  const getCurrentPosition = () => {
    if (window.navigator.geolocation) {
      console.log(window.navigator.geolocation)
      console.log("geolocationWatchID.current", geolocationWatchID.current)
      if (geolocationWatchID.current) {
        navigator.geolocation.clearWatch(geolocationWatchID.current);
      }
      geolocationWatchID.current = navigator.geolocation.watchPosition(position => {}, error => {
        if (error.code === error.PERMISSION_DENIED) {
          setIsRetrievingPosition(false)
        }
      });
      setIsRetrievingPosition(true)
      window.navigator.geolocation.getCurrentPosition(position => {
        console.log("inside getCurrentPosition")
        const {latitude, longitude} = position.coords;
        const currentPositionPoint = { "type": "Point", "coordinates": [longitude, latitude] };
        // const locationState = STATES.features.find(feature => booleanPointInPolygon(currentPositionPoint, feature));
        setTimeout(() => {
          onStateSelected("none")
        })
        console.log("currentPositionPoint is:", currentPositionPoint);
        onGetCurrentPosition(currentPositionPoint);
        setIsRetrievingPosition(false);
      });
    }
  }

  if(label){
    return(
      <div onClick={getCurrentPosition} style={{display: "flex", alignItems: "center"}}>
        <Fab className={classes && classes.iconButton}>
          { isRetrievingPosition && <CircularProgress size={20} /> }
          { !isRetrievingPosition && <MyLocationIcon className={classes && classes.innerIcon} style={{fontSize: 18}}/>}
        </Fab>
        <div>
          {label}
        </div>
      </div>
    )
  }

  return (
    <Fab onClick={getCurrentPosition} className={clsx(classes && classes.iconButton, "fab-override")}>
      { isRetrievingPosition && <CircularProgress size={20} /> }
      { !isRetrievingPosition && <MyLocationIcon className={classes && classes.innerIcon} style={{fontSize: 18}}/>}
    </Fab>
  )
}
