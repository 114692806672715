import Colors from "../../constants/Colors"

const splitTabPanelStyle = (theme) => ({
  customTabsRoot: {
    color: Colors.white,
    borderRadius: "5px",
    [theme.breakpoints.up("md")]:{
      width: "50%",
      padding: "14px"
    },
  },
  tabsContainer: {
    color: Colors.white
  },
  cardBody: {
    [theme.breakpoints.down("sm")]:{
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  cardClass: {
    marginBottom: "0 !important",
    [theme.breakpoints.up('md')]: {
      maxWidth: 860,
    },
    [theme.breakpoints.down("sm")]:{
      paddingLeft: 0,
      paddingRight: 0
    },
  },
});
export default splitTabPanelStyle;
