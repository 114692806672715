import {fromJS} from 'immutable';
import MAP_STYLE from '../assets/map/style.json';
import IDEAL_MONOCHROME from '../assets/map/monochrome.json';

export const dataLayer = fromJS({
  id: 'data',
  type: 'symbol',
  source: 'locations',
  layout: {
    'icon-image': 'hospital-15',
    'icon-allow-overlap': true,
  }
});

export const defaultMapStyle = fromJS(IDEAL_MONOCHROME);
