import Colors from "./Colors"

export const buttonSpacing = {
  small: {
    paddingRight: "10px",
    paddingLeft: "10px",
  },
  large: {
    paddingRight: "20px",
    paddingLeft: "20px",
    marginLeft: "10px",
  },
};

export const contentSpacingOverrideMax = {
  width: "100%",
};

export const formStyles = {
  customInput: {
    border: `2px solid ${Colors.azure}`,
    borderRadius: 2,
    height: 44,
    width: "100%",
    resize: "vertical",
    minHeight: 44,
    rows: 4,
    fontFamily: "Lato, Verdana, Arial, sans-serif",
  },
  errorInput: {
    border: `2px solid red`,
    borderRadius: 2,
    height: 44,
    width: "100%",
    resize: "vertical",
    minHeight: 44,
    rows: 4,
    fontFamily: "Lato, Verdana, Arial, sans-serif",

  },

}

export const griddedStyles = {
  cardContainer: {

  },
  nestedCardGrid: {
    paddingTop: "64px",
    borderRadius: 5,
  },

}
