import React, {PureComponent} from 'react';
import { Typography } from "@material-ui/core"
import Colors from "../constants/Colors"
import { CustomButton } from "./CustomButtons/CustomButton"
import { Link } from "gatsby"
import {humanizeDistance} from "./widgets/ClinicMap"
import Button from '../vendor/mui-pro/src/components/CustomButtons/Button.jsx';
import { getDirections } from "../hooks/routing"


export default class ClinicInfo extends PureComponent {
  render() {
    const {clinic} = this.props;

    return (
      <div style={{
        padding: "18px 18px 5px",
      }}>
        <Typography variant="h3" style={{color: Colors.mineShaft, width: "100%", marginBottom: 10}}>{clinic.get('title')}</Typography>
        <Typography style={{marginBottom: 5}} variant="h5">{humanizeDistance(clinic.get('distance'))}</Typography>
        <Typography style={{maxWidth: 170, color: Colors.tundora, fontWeight: 400}} variant="h6">{clinic.get('address')}</Typography>
        <Link to={`/suboxone-clinics/${clinic.get('slug')}`} style={{textDecoration: "none", alignSelf: "center"}}>
          <Button color="primary" size="sm" style={{boxShadow: "none"}}>
            <Typography variant="button">Details</Typography>
          </Button>
        </Link>
        <Button
          onClick={() => getDirections(clinic.get("address"))}
          color="secondary"
          size="sm"
          style={{
          alignSelf: "center",
          color: Colors.azure,
          marginRight: 10,
          marginLeft: 12,
            boxShadow: "none",
        }}>
          <Typography variant="button">Get directions</Typography>
        </Button>
      </div>
    );
  }
}
