export const standardPadding = {
  mobile:{
    paddingTop: "42px",
    paddingLeft: "16px",
    paddingRight: "16px",
  },
  desktop: {
    paddingTop: "48px",
    paddingLeft: "32px",
    paddingRight: "32px",
  },
  standardWidgetBottom: {
    marginBottom: 64,
    "@media screen and (max-width: 600px)": {
      marginBottom: 32,
    }
  },
  standardWidgetBottomPadding: {
    paddingBottom: 64,
    "@media screen and (max-width: 600px)": {
      paddingBottom: 32,
    }
  },
  standardWidgetBottomPaddingMobile: {
    paddingBottom: 32,
  },
  standardWidgetTopPadding: {
    paddingTop: 64,
    "@media screen and (max-width: 600px)": {

    },
  },
  gatsbyImageSectionSpacing: {
    paddingLeft: 32,
    paddingRight: 32,
    "@media screen and (max-width: 600px)": {
      paddingLeft: 16,
      paddingRight: 16,
    }
  }
}

export const paddingAdjustments = {
  mobileOnly: {
    stripVertical: {
      paddingTop: "0 !important",
      paddingBottom: "0 !important",
    },
  }
}

export const standardMargins = {
  bottom: {
    contentInclusive: {
      marginBottom: `calc(64px - 1.45rem)`,
      "@media screen and (max-width: 600px)": {
        marginBottom: `calc(32px - 1.45rem)`,
      }
    }
  }
}