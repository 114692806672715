import { createStyles, makeStyles } from "@material-ui/core"
import Colors from "../../constants/Colors"
import { container } from "../../vendor/mui-pro/src/assets/jss/material-kit-pro-react"

const CustomTabPanelStyle = (theme, props) => ({
  cardBodyBackground: {
    backgroundColor: "orange"
  },
  cardClass: {
    ...container,
  },
  customTabsRoot: {
    backgroundColor: Colors.kashmirBlue,
    padding: 14,
    color: Colors.white,
    borderRadius: "5px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px"
    },
  },
  tabsContainer: {
    color: Colors.white,
  },
  customTabWrapper: {
    ...theme.typography.h6,
    color: Colors.white,
    "& > span": {
      marginBottom: "0px !important",
    }
  },
  noPaddingAndMargin: {
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: 0,
    marginRight: 0,
  }
});

export default CustomTabPanelStyle;