import React from "react";
import { graphql, useStaticQuery } from "gatsby"
import MenuItem from "@material-ui/core/MenuItem"
import { Typography, useMediaQuery } from "@material-ui/core"
import Colors from "../../constants/Colors"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import {fromJS} from 'immutable';
import clsx from "clsx"
import { blue } from "@material-ui/core/colors"
import useTheme from "@material-ui/core/styles/useTheme"


export default function({primary,
                          secondary,
                          value,
                          defaultColor,
                          updateValue,
                          rootStyle,
                          selectMenuProps,
                          selectClasses,
                          menuItemClasses,
                          iconColor,
                          textColor,
                          blueText,
                          iOS
}) {

  const data = useStaticQuery(graphql`
    query ClinicStatesQuery {
      allDatoCmsClinic {
        edges {
          node {
            state
          }
        }
      }
    }
  `)
  const theme = useTheme();
  const stateNames = fromJS(data.allDatoCmsClinic.edges.flatMap(e => e.node)).map(f => f.get('state')).toSet().toList().sort();
  const smallBreakpoint = useMediaQuery(theme.breakpoints.down('xs'));
  const applyNative = smallBreakpoint || iOS

  return(
    <div style={{...rootStyle}}>
      <Select
        native={applyNative}
        fullWidth
        disableUnderline
        onChange={e => updateValue(e.target.value)}
        MenuProps={selectMenuProps}
        classes={selectClasses}
        value={value}
        className={clsx("state-select-hook", blueText ? "state-select-bluetext" : null)}
        style={{marginLeft: smallBreakpoint ? 0 : null}}
      >
        {
          !applyNative && stateNames &&
          <MenuItem value="none">
            <Typography variant="h6"
              style={{
                marginBottom: "2px !important",
                fontFamily: "Lato, Verdana, sans-serif;",
                fontWeight: "Bold",
                color: defaultColor || Colors.mineShaft,
                textTransform: "none",
              }} >Select your state</Typography>
          </MenuItem>
        }c
        {
          !applyNative && stateNames && stateNames.map((stateName) => {
          return(
          <MenuItem value={stateName} classes={menuItemClasses} >
            <Typography variant="h6" style={{
            fontFamily: "Lato, Verdana, sans-serif",
            fontWeight: "bold",
            color: textColor ? textColor : Colors.azure,
            textTransform: "none"}}>
              {stateName}
            </Typography>
          </MenuItem>
          )
          })
        }
        {
          applyNative &&
            <option style={{color: textColor ? textColor : Colors.azure}}>
              Select your state
            </option>
        }
        {
          applyNative && stateNames.map((stateName) => (
            <option style={{color: textColor ? textColor : Colors.azure}}>
              {stateName}
            </option>
          ))
        }
      </Select>
    </div>
  )
}
