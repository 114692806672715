/*!

=========================================================
* Material Kit PRO React - v1.7.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/Card/CardContent";
// import CardHeader from "@material-ui/core/Card/CardHeader";
import Tabs from "@material-ui/core/Tabs/index";
import Tab from "@material-ui/core/Tab/index";

// core components
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import customTabsStyle from "../../assets/jss/material-kit-pro-react/components/customTabsStyle.jsx";

/* CUSTOM STYLING */
import CustomTabPanelStyle from "../../../../../components/TabPanel/tabPanelCustomStyles";
import splitTabPanelStyle from "../../../../../components/TabPanel/splitTabPanelStyles";
import { Typography } from "@material-ui/core"
import clsx from "clsx"
import { navigate } from '@reach/router';
import queryString from 'query-string';

import { slugify } from 'underscore.string';
import Colors from "../../../../../constants/Colors"


const dynamicStyles = {
  cardClass: {
    backgroundColor: props => props.bodyColor || null
  },
}


class CustomTabsBase extends React.Component {

  handleChange = (event, value) => {
    navigate('?tab=' + value);
  };

  render() {
    const {
      classes,
      headerColor,
      title,
      tabs,
      rtlActive,
      plainTabs,
      split,  //prop referencing if multi-header layout
      cardClass, //Custom property for modifying cards
      customTabPanel, //! Only use for SplitTabs layout, is
      noPadding, //
      noPaddingAndMargin,
      slightTopPadding, //added
      bodyColor, //Custom property to set background color on card
    } = this.props;

    const tabsWithSlugs = tabs.map(t => ({...t, ...{ slug: slugify(t.tabName) }}));
    const parsed = (typeof window !== "undefined" && queryString.parse(window.location.search));
    const selectedTab = (parsed && parsed.tab && tabsWithSlugs.find(t => t.slug === parsed.tab)) || tabsWithSlugs[0];

    const cardTitle = classNames({
      [classes.cardTitle]: true,
      [classes.cardTitleRTL]: rtlActive
    });
    const tabsContainer = classNames({
      [classes.tabsContainer]: true,
      [classes.tabsContainerRTL]: rtlActive,
    });
    const tabPanelOuterPadding = clsx({
      [classes.noPaddingAndMargin]: noPaddingAndMargin
    })
    return (
      <Card plain={plainTabs} className={clsx(classes.cardClass, noPadding ? classes.noPadding : null)}
        classes={{
          card: classes.card
        }}
      >
        <CardHeader color={headerColor} plain={plainTabs}
          classes={{
            cardHeader: tabPanelOuterPadding
          }}
        >
          {title !== undefined ? (
            <div className={cardTitle}>{"title"}</div>
          ) : null}
          <Tabs
            className="tabs-base-override"
            variant="scrollable"
            scrollButtons="off"
            classes={{
              root: classes.customTabsRoot,
              flexContainer: tabsContainer,
              indicator: classes.displayNone
            }}
            value={selectedTab.slug}
            onChange={this.handleChange}
            textColor="inherit"
          >
            {tabsWithSlugs.map((prop, key) => {
              var icon = {};
              if (prop.tabIcon !== undefined) {
                icon = {
                  icon: <prop.tabIcon className={classes.tabIcon} />
                };
              } else {
                icon = {};
              }
              return (
                <Tab
                  className="tabs-override"
                  key={key}
                  classes={{
                    root: classes.customTabRoot,
                    selected: classes.customTabSelected,
                    wrapper: classes.customTabWrapper
                  }}
                  // icon={<prop.tabIcon className={tabIcon} />}
                  {...icon}
                  value={prop.slug}
                  label={prop.tabName}
                />
              );
            })}
          </Tabs>
          {
            customTabPanel
          }
        </CardHeader>
        {
          split &&
          <CardHeader
            classes={{
              root: classes.secondaryTabRootrdHeader,
              title: cardTitle,
              content: classes.secondaryCardHeaderContent,
              action: classes.secondaryCardHeaderAction
            }}
            title={title}
          >
            <div>
            </div>
          </CardHeader>
        }
        <CardBody className={clsx(classes.cardBody, classes.customCardBody)}
                  noPadding={noPadding}
        >
          {tabsWithSlugs.map((prop, key) => {
            if (prop.slug === selectedTab.slug) {
              return <div key={key}>{prop.tabContent}</div>;
            }
            return null;
          })}
        </CardBody>
      </Card>
    );
  }
}

CustomTabsBase.defaultProps = {
  headerColor: "purple"
};

CustomTabsBase.propTypes = {
  classes: PropTypes.object.isRequired,
  headerColor: PropTypes.string,
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      tabName: PropTypes.string.isRequired,
      tabIcon: PropTypes.object,
      tabContent: PropTypes.node.isRequired
    })
  ),
  rtlActive: PropTypes.bool,
  plainTabs: PropTypes.bool,
  customTabsStyle: PropTypes.string
};

const CustomTabs = withStyles(customTabsStyle)(CustomTabsBase);
const _IdealTabs = withStyles(CustomTabPanelStyle)(CustomTabs);
const IdealTabs = withStyles(dynamicStyles)(_IdealTabs);
const IdealSplitTabs = withStyles(splitTabPanelStyle)(IdealTabs);

export { CustomTabs, IdealTabs, IdealSplitTabs };
