import React from "react"
import Colors from "../../constants/Colors";

import Button from "../../vendor/mui-pro/src/components/CustomButtons/Button";
import {buttonSpacing} from "../../constants/cssMixins"
import clsx from "clsx"
import classNames from "classnames"
import { createStyles, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => createStyles({
  white: {
    backgroundColor: "white",
    color: "#0d47a1",
    border: '1px solid #E4E8EE',
    boxShadow: "none",
    fontWeight: "bold",
  },
  noLeftMargin: {
    marginLeft: 0,
  }
}));

export const CustomButton = ({style, children, color, transparent, classes, large, link, onClick, left}) => {
  const styles = useStyles();
  const receivedStyle = style || {};
  var buttonClasses = null;

  if(classes){
    buttonClasses = clsx({
      [classes.root]: true,
      [styles.noLeftMargin]: left ? true : false,
    })
  }

  const transtyle = () => {
    if(transparent){
      return {backgroundColor: color || "rgba(0,0,0,0.5)",
        border: "2px solid rgb(255, 255, 255)"}
    } else {
      if(color && (color === Colors.white  || color === "white")){  //Edge case: white text on white button
        return {backgroundColor: color || Colors.tulipTree, color: Colors.azure}
      }
      return {backgroundColor: color || Colors.tulipTree}
    }
  }
  const appliedStyles = large ? Object.assign(receivedStyle, buttonSpacing.large) : Object.assign(receivedStyle, buttonSpacing.small);
  const correctstyles = transtyle();
  return(<Button className={clsx(buttonClasses, color === "white" ? styles.white : null)} style={{...appliedStyles, ...correctstyles, ...style}} onClick={onClick} href={link}>{children}</Button>)
}
