import { useStaticQuery, graphql, navigate } from "gatsby"

export const useAllPageData = () => {
  const { allSitePage } = useStaticQuery(
    graphql`
      query AllPages {
        allSitePage {
          edges {
            node {
              path
              context {
                id
              }
            }
          }
        }
      }
    `
  )
  return allSitePage.edges
}

export const getDirections = (address) => {
  window.open(`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`)
}

export const launch88 = () => {
  //Error thrown if try to call bus before loaded. If not loaded, delay call for
  //reasonable time until loaded.
  //The bus is set as part of the 8x8 initialization in the layout.js component.
  if(!window.bus){
    setTimeout( () => {
      window.bus.publish("chat:trigger-invitation");
    },5000)
  } else {
    window.bus.publish("chat:trigger-invitation");
  }
}

export const jumpToSection = (hash, mobile) => {
  debugger;
  if(hash === "#ContactForm"){
    const el = document.getElementById('ContactForm')
    window.scrollTo({top: el.offsetTop - 82, behavior: "smooth"}) //82 is the height of the header
  } else {
    const anchor = document.querySelector(hash);
    if(anchor){
      const pos = anchor.getBoundingClientRect().top;
      window.scrollTo({top:pos - 82, behavior: "smooth"})
    }
  }
}

export const detectExternalLink = (link) => {
  const regLink = RegExp(/(http:\/\/)\w*/);
  const regLinkSecure = RegExp(/(https:\/\/)\w*/);
  return regLink.test(link) || regLinkSecure.test(link);
}

export const getRouteForLocatable = (allPageData, datoItem) => {
  if(!datoItem) { return ; }
  const sitePage = allPageData.find((page) => (page.node.context && page.node.context.id) === datoItem.id )
  return sitePage && sitePage.node.path
}
